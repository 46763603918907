@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Inconsolata");

.App {
  background: linear-gradient(20deg, 
    rgba(35, 35, 35, 1) 0%, 
    rgba(38, 38, 38, 1) 26%, 
    rgba(0, 0, 0, 0.84) 100%, 
    rgba(10, 10, 10, 0.57) 100%
  );
  padding: 0;
  margin: 0;
  height: 100vh;
}

body {
  margin: 0;
  padding: 0;
  height: 90vh;
  background: linear-gradient(130.79deg, #232323 26.84%, #262626 53.62%, rgba(0, 0, 0, 0.83871) 101.2%, rgba(10, 10, 10, 0.574194) 110.1%, rgba(10, 4, 4, 0.329032) 113.67%, rgba(0, 0, 0, 0.174194) 116.36%, rgba(0, 0, 0, 0) 131.01%);
  border-radius: 30px;
  font-family: 'Poppins';
  scrollbar-width: none;
  /* overflow: hidden; */
}

::-webkit-scrollbar {
  display: none;
}
