.mentionWrapper {
  all: unset;
  width: 100%;
  background: transparent;
  font-size: 0.9rem;
  font-family: 'Poppins';
  color: #ffffff;
  font-weight: 200;
  position: relative;
}

.mentionWrapper .mentionWrapper__control {
  min-height: 30px;
  max-height: 80px;
  position: relative;
  color: #ffffff;
  overflow: hidden;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__highlighter {
  padding: 0.3rem 0.1rem;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  white-space: pre-wrap;
  pointer-events: none;
  color: transparent;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input {
  padding: 0.3rem 0.1rem;
  border: 0;
  resize: none;
  font-size: 0.9rem;
  color: #ffffff;
  background: transparent;
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  outline: none;
}

.mentionWrapper .mentionWrapper__control .mentionWrapper__input::placeholder {
  color: #6b7280;
}

.mentionWrapper .mentionWrapper__control:focus-within {
  outline: 1px solid #5b9dd9;
  outline-offset: 1px 2px;
}

.mentionWrapper__suggestions {
  background-color: rgba(0, 0, 0, 0.6) !important;
  padding: 10px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.8rem;
}

.mentionWrapper__suggestions .mentionWrapper__suggestions__list {
  font-size: 14px;
  max-height: 65px;
  overflow: scroll;
}

.mentionWrapper .mentionWrapper__suggestions .mentionWrapper__suggestions__item--focused {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  font-weight: 600;
}
