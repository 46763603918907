.postMentionWrapper {
  all: unset;
  width: 100%;
  background: transparent;
  font-size: 0.9rem;
  font-family: 'Poppins';
  color: #ffffff;
  font-weight: 400;
  position: relative;
}

.postMentionWrapper .postMentionWrapper__suggestions {
  position: initial !important; /* Reset other styles */
  top: auto !important;
  right: auto !important;
  z-index: auto !important;
  background-color: transparent !important;
  margin-top: 0 !important;
  min-width: 100px !important;
  max-width: 150px !important;
}

.postMentionWrapper .postMentionWrapper__control {
  min-height: 80px;
  max-height: 120px;
  position: relative;
  color: #ffffff;
  overflow: hidden;
}

.postMentionWrapper .postMentionWrapper__control .postMentionWrapper__highlighter {
  padding: 8px 10px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  white-space: pre-wrap;
  pointer-events: none;
  color: transparent;
}

.postMentionWrapper .postMentionWrapper__control .postMentionWrapper__input {
  padding: 0.5rem 0.75rem;
  border: 0;
  resize: none;
  font-size: 0.9rem;
  color: #ffffff;
  background: transparent;
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  outline: none;
}

.postMentionWrapper .postMentionWrapper__control .postMentionWrapper__input::placeholder {
  color: #6b7280;
}

.postMentionWrapper .postMentionWrapper__control:focus-within {
  outline: 1px solid #5b9dd9;
  outline-offset: 1px 2px;
}

.postMentionWrapper__suggestions {
  background-color: rgba(0, 0, 0, 0.6) !important;
  padding: 10px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.8rem;
}

.postMentionWrapper__suggestions .postMentionWrapper__suggestions__list {
  font-size: 14px;
  max-height: 65px;
  overflow: scroll;
}

.postMentionWrapper .postMentionWrapper__suggestions .postMentionWrapper__suggestions__item--focused {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  font-weight: 600;
}


/* BEGIN: Mentions wrapper for post titles */

.postTitleMentionWrapper {
  all: unset;
  width: 100%;
  background: transparent;
  font-size: 0.9rem;
  font-family: 'Poppins';
  color: #ffffff;
  font-weight: 400;
  position: relative;
}

.postTitleMentionWrapper .postTitleMentionWrapper__suggestions {
  position: initial !important; /* Reset other styles */
  top: auto !important;
  right: auto !important;
  z-index: auto !important;
  background-color: transparent !important;
  margin-top: 0 !important;
  min-width: 100px !important;
  max-width: 150px !important;
}

.postTitleMentionWrapper .postTitleMentionWrapper__control {
  min-height: 45px;
  max-height: 45px;
  position: relative;
  color: #ffffff;
  overflow: hidden;
}

.postTitleMentionWrapper .postTitleMentionWrapper__control .postTitleMentionWrapper__highlighter {
  padding: 5px 11px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  white-space: pre-wrap;
  pointer-events: none;
  color: transparent;
}

.postTitleMentionWrapper .postTitleMentionWrapper__control .postTitleMentionWrapper__input {
  padding: 0.5rem 0.75rem;
  border: 0;
  resize: none;
  font-size: 0.9rem;
  color: #ffffff;
  background: transparent;
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  overflow: hidden;
  box-sizing: border-box;
  outline: none;
}

.postTitleMentionWrapper .postTitleMentionWrapper__control .postTitleMentionWrapper__input::placeholder {
  color: #6b7280;
}

.postTitleMentionWrapper .postTitleMentionWrapper__control:focus-within {
  outline: 1px solid #5b9dd9;
  outline-offset: 1px 2px;
}

.postTitleMentionWrapper__suggestions {
  background-color: rgba(0, 0, 0, 0.6) !important;
  padding: 10px;
  box-shadow: 0px 0px 11px 0px rgba(0, 0, 0, 0.75);
  border-radius: 0.8rem;
}

.postTitleMentionWrapper__suggestions .postTitleMentionWrapper__suggestions__list {
  font-size: 14px;
  max-height: 65px;
  overflow: scroll;
}

.postTitleMentionWrapper .postTitleMentionWrapper__suggestions .postTitleMentionWrapper__suggestions__item--focused {
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
  font-weight: 600;
}