.feat-title-box {
    padding: 5px 15px;
    font-size: 14px;
    font-weight: bold;
    color: #2b2b2b;
    background: linear-gradient(90deg, #00ffea 0%, #9c5aff 100%);
    border-radius: 12px;
    text-align: center;
    text-transform: uppercase;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    align-self: flex-start; /* Keep the width as short as needed */
}

.feat-main-box {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: flex-start;
}

.content-box {
    display: flex;
    align-items: center;
    gap: 10px; /* Adjusted gap between text and image */
    max-width: 100%;
    white-space: nowrap;
}

.text-box {
    display: flex;
    gap: 2px;
    font-size: 16px;
    color: white;
}

.streak-image {
    height: 100px; /* Increased the height of the image */
    object-fit: contain; /* Maintain image aspect ratio */
}
